#btn-outiline-wite-round{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: solid 2px #fff;
    color: #fff;
    width: 100%;
    font-weight: bold;
    text-transform: inherit;
    border-radius: 3px;
    cursor: pointer;
    padding: 2px 20px;
    padding-top: 4px;
    border-radius: 50px;
}
#btn-outiline-wite-round svg{
    margin-right: 10px;
}
#btn-outiline-wite-round:hover{
    opacity: 0.5;
}