#btn-green-dark{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #005440;
    border: none;
    color: #fff;
    /* height: 50px; */
    width: 100%;
    font-weight: bold;
    text-transform: inherit;
    border-radius: 3px;
    cursor: pointer;
}
#btn-green-dark svg{
    margin-right: 10px;
}
#btn-green-dark:hover{
    background-color: #01362a;
}
#btn-green-dark:disabled{
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: all !important;
}