.box-actions-imoveis{
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
}

.box-form-cadastro-imovel{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;

    
}
.box-form-cadastro-imovel form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.box-form-cadastro-imovel h2{
    color: #9C9C9C;
    font-weight: 400;
}

.container-step-1-novo-imovel{
    width: 450px;
}

.container-step-2-novo-imovel{
    width: 650px;
}


.box-fotos-detalhes-imoveis{
    display: flex;
    flex-direction: column;
}
.box-fotos-detalhes-imoveis aside{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.box-fotos-detalhes-imoveis .element-foto:first-child{
    margin-left: 0;
}
.box-fotos-detalhes-imoveis .element-foto{
    display: flex;
    flex-direction: column;
    width: 100%;
    border: solid 3px #2B2B2B;
    border-radius: 5px;
    width: 195px;
    height: 135px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.box-fotos-detalhes-imoveis .box-element-foto{
    display: flex;
    flex-direction: column;
    /* flex-grow: 2; */
    width: 195px;
    height: 135px;
    margin-top: 15px;
    margin-left: 7px;
    margin-right: 7px;
}

.box-fotos-detalhes-imoveis .element-foto .box-deleteFotoDImovel{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.box-fotos-detalhes-imoveis .element-foto .deleteFotoDImovel{
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D50000;
    border: none;
    border-radius: 29px;
    cursor: pointer;    
    position: relative;
    top: -10px;
    right: -10px;
    z-index: 1;
}

.box-fotos-detalhes-imoveis .element-foto .box-abrirFotoDImovel{
    display: flex;
    flex: 1;
    justify-content: center;
}
.box-fotos-detalhes-imoveis .element-foto .box-abrirFotoDImovel button{
    display: flex;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;    
    position: relative;
    bottom: -15px;
    align-self: flex-end;
    background-color: #2B2B2B;
}



.box-fotos-detalhes-imoveis .box-button-upload{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
}
.box-fotos-detalhes-imoveis .box-button-upload p{ font-size: 12px; color: #858585;}



.box-content-detalhes-estoque{
    background-color: #fff;
    border-radius: 5px;
    padding: 25px;
    -webkit-box-shadow: 0px 9px 7px -6px rgba(0,0,0,0.04);
    -moz-box-shadow: 0px 9px 7px -6px rgba(0,0,0,0.04);
    box-shadow: 0px 9px 7px -6px rgba(0,0,0,0.04);
}
.box-content-detalhes-estoque .title{ color: #4A4A4A; margin-top: 0px; font-size: 16px;}
.header-box-content-detalhes-estoque{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.box-anexos{
    display: flex;
    flex-direction: column;
}

.box-anexos aside{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.box-element-anexo .title{
    width: 100%;
    height: 47px;
    padding-bottom: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;  /* numero de linhas a limitar */
    overflow: hidden;
    -webkit-box-orient: vertical; 
}
.box-element-anexo .title p{
    font-size: 15px;
}

.box-element-anexo{
    display: flex;
    flex-direction: column;
    /* flex-grow: 2; */
    width: 195px;
    height: 135px;
    margin-top: 25px;
    margin-left: 7px;
    margin-right: 7px;
    margin-bottom: 50px;
}

.element-anexo:first-child{
    margin-left: 0;
}
.element-anexo{
    display: flex;
    flex-direction: column;
    width: 100%;
    border: solid 3px #2B2B2B;
    border-radius: 5px;
    width: 195px;
    height: 135px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.element-anexo .iconTypeFile{
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2B2B2B;
    border: none;
    border-radius: 25px;
    cursor: pointer;    
    position: relative;
    top: -10px;
    left: -10px;
    z-index: 1;
}

.element-anexo .box-header-item-anexo{
    display: flex;
    justify-content: space-between;

    width: 100%;
}

.element-anexo .box-abrirAnexo{
    display: flex;
    flex: 1;
    justify-content: center;
}
.element-anexo .box-abrirAnexo button{
    display: flex;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;    
    position: relative;
    bottom: -15px;
    align-self: flex-end;
    background-color: #2B2B2B;
    margin-right: 6px;
}

.element-anexo .box-abrirAnexo .btnWhatsapp{
    display: flex;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;    
    position: relative;
    bottom: -15px;
    align-self: flex-end;
    background-color: #29a71a;
    margin-right: 6px;
}

.box-button-upload{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
}
.box-button-upload p{ font-size: 12px; color: #858585;}

.element-anexo .deleteAnexo{
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D50000;
    border: none;
    border-radius: 29px;
    cursor: pointer;    
    position: relative;
    top: -10px;
    right: -10px;
    z-index: 1;
}

.box-anexos aside{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.box-legendas-status{
    display: flex;
}
.box-legendas-status article{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
}
.box-legendas-status article span{
    width: 15px;
    height: 15px;
    border-radius: 15px;
    margin-right: 5px;
}
.box-legendas-status article p{ font-size: 14px; color: #757575; }

.ativo{ background-color: #43B968; }
.inativo{ background-color: #F44236; }

.dotStatus{ 
    width: 15px;
    height: 15px;
    border-radius: 15px;
}


@media (max-width: 800px){
    .container-step-2-novo-imovel{
        width: 100%!important;
    }
}
