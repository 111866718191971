 body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F3F5F8;
}



.datatable-green-defalt .MuiDataGrid-columnHeaders{
  background-color: #005440;
  color: #fff;
  min-height: 40px!important;
  max-height: 40px!important;
  line-height: 40px!important;
}
.datatable-green-defalt .MuiDataGrid-virtualScroller{
  margin-top: 40px!important;
}
.datatable-green-defalt .MuiDataGrid-columnSeparator svg{
  fill: #005440;
}
.datatable-green-defalt .MuiDataGrid-columnHeaders .MuiCheckbox-root svg{
  fill: #fff;
}

.datatable-green-defalt .css-1cqdunb-MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .css-1cqdunb-MuiDataGrid-root .MuiDataGrid-cell:focus{
  outline: solid transparent 2px!important;
}

.focused-row {
  background-color: #9fd1c5!important;
  color: #333;
}


.input input{
  background-color: #fcfcfc;
}
.input .MuiFormControl-fullWidth {
  background-color: #fcfcfc;
}
.input .MuiSelect-select{
  background-color: #fcfcfc;
}
.input .MuiOutlinedInput-input{
  background-color: #fcfcfc;
}

.fancybox__container{
  z-index: 2000!important;
}
.fancybox__content {
   background-color: transparent!important;
}
.fancybox__carousel .fancybox__slide.has-iframe .fancybox__content, .fancybox__carousel .fancybox__slide.has-map .fancybox__content, .fancybox__carousel .fancybox__slide.has-pdf .fancybox__content{
  /* height: 900px!important; */
  height: 100%!important;
}


a{
  text-decoration: none;
  color: #02A680;
}
a:hover{
  color: #005440;
}
.MuiFormHelperText-root{
  text-align: left!important;
}

.MuiTab-textColorPrimary.Mui-selected{
  color: #02A680!important;
}
.css-1aquho2-MuiTabs-indicator{
  background-color: #02A680!important;
}

.MuiTabs-indicator{
  background-color: #02A680!important;
}

.css-1nr2wod.Mui-checked{
  color: #02A680!important;
}

.css-1nr2wod.Mui-checked + .MuiSwitch-track {
  background-color: #02A680!important;
}

.css-zun73v.Mui-checked, .css-zun73v.MuiCheckbox-indeterminate {
  color: #02A680!important;
}

.css-4ff9q7.Mui-active {
  color: #02A680!important;
}



.PrivateTabIndicator-colorPrimary-2{
  background-color: #02A680!important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
  text-transform: initial!important;
}
.MuiTabPanel-root{ padding: 20px 0!important;}
.MuiDataGrid-cell{ cursor: pointer!important;}

.css-1l84z42-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline-color: #02A680!important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
  fill: #02A680!important;
}


/* Inputs color */
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #02A680!important;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #02A680!important;
}

.css-1bp1ao6.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #02A680!important;
}

/* .css-u9osun.Mui-focused{
  color: #02A680!important;
} */

.css-fvipm8.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #02A680!important;
}

.css-ub1r1.Mui-selected:hover {
  background-color: #005440!important;
}

.css-ub1r1.Mui-selected {
  background-color: #02A680!important;
}


/* Input date */
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #02A680!important;
}
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
  background-color: #02A680!important;
}
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover{
  background-color: #005440!important;
}
.css-m1gykc.Mui-selected:focus, .css-m1gykc.Mui-selected{
  background-color: #02A680!important;
}
.css-m1gykc.Mui-selected:focus, .css-m1gykc.Mui-selected:hover{
  background-color: #005440!important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #02A680!important;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #02A680!important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  color: #02A680!important;
}

.css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #02A680!important;
}
.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #02A680!important;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #02A680!important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #02A680!important;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #02A680!important;
}

.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #02A680!important;
}

/* .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-error {
  color: #d32f2f!important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #02A680;
} */
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
  color: #005440!important;
}

/* react-date-range */
.rdrDayToday .rdrDayNumber span:after{
  background: #005440!important;
}
.rdrStartEdge{
  color: #02A680!important;
}
.rdrInRange{
  color: #02A680!important;
}
.rdrEndEdge{
  color: #02A680!important;
}
.rdrDefinedRangesWrapper .rdrStaticRangeSelected{
  color: #02A680!important;
}

.rdrDayStartPreview{
  color: #02A680!important;
}
.rdrDayInPreview{
  color: #02A680!important;
}
.rdrDayEndPreview{
  color: #02A680!important;
}

.rdrDateDisplayItemActive{
  border-color: #02A680!important;
}




/* #root{
  height: 100vh;
} */
.container{
  padding: 0 2%;
}
.container-page{
  padding-top: 70px;
  min-height: 85.5vh;
}
.container-data-grid{
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
}

.container-box{
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
}

.container-no-data-grid{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.MuiDataGrid-row:nth-child(even){ /* Stripted DataGrid */
  background-color: rgba(250, 250, 250, 0.603);
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3!important;
}

.swal2-container {
  z-index: 200000!important;
}

/* SCROLL */
/* Width of the vertical scrollbar */
::-webkit-scrollbar {
  width: 3px;
}

/* Width of the horizontal scrollbar */
::-webkit-scrollbar:horizontal {
  height: 4px;
}

/* Background of the vertical scrollbar */
::-webkit-scrollbar-track-piece {
  background-color: #EEE;
  border-left: 2px solid #CCC;
}

/* Background of the horizontal scrollbar */
::-webkit-scrollbar-track-piece:horizontal {
  background-color: #EEE;
  border-top: 2px solid #CCC;
}

/* Color of the vertical scrollbar thumb */
::-webkit-scrollbar-thumb:vertical {
  background-color: #052757;
}

/* Color of the horizontal scrollbar thumb */
::-webkit-scrollbar-thumb:horizontal {
  background-color: #052757;
}

/* Color of the vertical scrollbar thumb on hover */
::-webkit-scrollbar-thumb:vertical:hover {
  background-color: #000;
}

/* Color of the horizontal scrollbar thumb on hover */
::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: #000;
}