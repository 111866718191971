.container-login{
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100vh;
}

.container-login article{
    display: flex;
    flex: 5;
    flex-direction: column;
    background-image: url("../../../public/imgs/bg.jpg");
    background-position: center center;
	background-size: cover;
    background-repeat: no-repeat;
    background-attachment: inherit;
}

.container-login article .content{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    background-color: rgba(0, 84, 64, 0.7);
    color: #fff;
    padding: 10%;
}

.container-login article .content h1{ font-size: 2.5em;}
.container-login article .content p{ font-size: 1em; margin-top: -30px;}

.container-login aside{
    display: flex;
    flex: 4;
    flex-direction: column;
    background-color: #F3F5F8;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
}

.container-login aside form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-top: 4%;
}

.container-login aside form p{ color: #2B2B2B; font-size: 0.9em; text-align: center; }

.recoveryAccount{
    margin-top: 5%;
}
.HelpersMenu{
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.box-dev{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6%;
}
.box-dev p{ font-size: 0.7em; }
.logo-link:hover{
    opacity: 0.5!important;
}

@media (max-width: 700px){
    .container-login{
        height: 100%;
    }
    .container-login aside{
        height: 100vh;
    }
    .container-login aside form{
        width: 80%;
    }
}

@media screen and (max-width: 950px) {
    .container-login article{ 
        display: none;
    }
}
