.box-actions-produtos{
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
}

.box-legendas-status{
    display: flex;
}
.box-legendas-status article{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
}
.box-legendas-status article span{
    width: 15px;
    height: 15px;
    border-radius: 15px;
    margin-right: 5px;
}
.box-legendas-status article p{ font-size: 14px; color: #757575; }

.ativo{ background-color: #43B968; }
.inativo{ background-color: #F44236; }

.dotStatus{ 
    width: 15px;
    height: 15px;
    border-radius: 15px;
}


.box-fotos-detalhes-produtos .box-button-upload{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
}
.box-fotos-detalhes-produtos .box-button-upload p{ font-size: 12px; color: #858585;}

.box-fotos-detalhes-produtos{
    display: flex;
    flex-direction: column;
}
.box-fotos-detalhes-produtos aside{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.box-fotos-detalhes-produtos .element-foto:first-child{
    margin-left: 0;
}
.box-fotos-detalhes-produtos .element-foto{
    display: flex;
    flex-direction: column;
    width: 100%;
    border: solid 3px #2B2B2B;
    border-radius: 5px;
    width: 195px;
    height: 135px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.box-fotos-detalhes-produtos .box-element-foto{
    display: flex;
    flex-direction: column;
    /* flex-grow: 2; */
    width: 195px;
    height: 135px;
    margin-top: 15px;
    margin-left: 7px;
    margin-right: 7px;
}

.box-fotos-detalhes-produtos .element-foto .box-deleteFotoDProduto{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.box-fotos-detalhes-produtos .element-foto .deleteFotoDProduto{
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D50000;
    border: none;
    border-radius: 29px;
    cursor: pointer;    
    position: relative;
    top: -10px;
    right: -10px;
    z-index: 1;
}

.box-fotos-detalhes-produtos .element-foto .box-abrirFotoDProduto{
    display: flex;
    flex: 1;
    justify-content: center;
}
.box-fotos-detalhes-produtos .element-foto .box-abrirFotoDProduto button{
    display: flex;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;    
    position: relative;
    bottom: -15px;
    align-self: flex-end;
    background-color: #2B2B2B;
}



.box-fotos-detalhes-produtos .box-button-upload{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
}
.box-fotos-detalhes-produtos .box-button-upload p{ font-size: 12px; color: #858585;}