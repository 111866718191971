/* MENSAGENS STATUS */
.box-mensagens-orcamentos{
    height: 400px;
    padding: 5px;
}

.box-mensagens-orcamentos article{
    width: 100%;
    height: 400px;
    border: solid 1px #C2BDBD;
    border-radius: 5px;
    background-color: #f4fefb;
    overflow-y: scroll;
}

.box-mensagens-orcamentos article .box-msg{
    /* width: 40%; */
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
    
}
.box-mensagens-orcamentos article .box-msg h3{
    font-size: 15px;
    margin-bottom: 5px; 
    color: #4A4A4A;
}
.box-mensagens-orcamentos article .box-msg aside{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 5px 10px;
}

.box-mensagens-orcamentos article .box-msg aside p{
    color: #fff;
    font-size: 14px;
}
.box-mensagens-orcamentos article .box-msg aside span{
    display: flex;
    justify-content: flex-end;
}

.box-mensagens-orcamentos article .box-msg aside span button{
    text-transform: none;
    color: #161616;
    font-size: 13px;
    padding: 0;
}

.box-mensagens-orcamentos article .right{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.box-mensagens-orcamentos article .left{
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.box-mensagens-orcamentos article .right .box-msg aside{
    background-color: #02A680;
}

.box-mensagens-orcamentos article .left .box-msg aside{
    background-color: #434343;
}


.box-mensagens-orcamentos article .left aside span button{
    color: #fff;
}


.element-anexo-for-msg-orcamentos:first-child{
    margin-left: 0;
}
.element-anexo-for-msg-orcamentos{
    display: flex;
    flex-direction: column;
    width: 100%;
    border: solid 3px #2B2B2B;
    border-radius: 5px;
    width: 300px;
    height: 90px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.element-anexo-for-msg-orcamentos .iconTypeFile{
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2B2B2B;
    border: none;
    border-radius: 25px;
    cursor: pointer;    
    position: relative;
    top: -10px;
    left: -10px;
    z-index: 1;
}

.element-anexo-for-msg-orcamentos .box-abrirAnexo{
    display: flex;
    flex: 1;
    justify-content: center;
}
.element-anexo-for-msg-orcamentos .box-abrirAnexo button{
    display: flex;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;    
    position: relative;
    bottom: -15px;
    align-self: flex-end;
    background-color: #2B2B2B;
    margin-right: 6px;
}




.box-subtotal-orcamento{
    line-height: 0;
    color: rgb(51, 51, 51);
    margin-right: 25px;
    padding-right: 25px;
    border-right: solid 2px #e6e6e6;
} 
.box-subtotal-orcamento:nth-child(3){
    border: none;
    margin: 0;
    padding: 0;
}
.box-subtotal-orcamento p{
    font-size: 13px;
}


@media (max-width: 560px){
    .box-mensagens-orcamentos article .box-msg{
        width: 70%!important;
    }
}