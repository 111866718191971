#btn-secondary-only-icon-green{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #29a71a;
    border: none;
    color: #fff;
    height: 50px;
    width: 100%;
    font-weight: bold;
    text-transform: inherit;
    border-radius: 3px;
    margin: 10px 0;
    cursor: pointer;
}

#btn-secondary-only-icon-green:hover{
    background-color: #1e8013;
}
#btn-secondary-only-icon-green:disabled{
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: all !important;
}