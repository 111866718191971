#btn-outiline-secondary{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: solid 2px #cacaca;
    color: #cacaca;
    height: 50px;
    width: 100%;
    font-weight: bold;
    text-transform: inherit;
    border-radius: 3px;
    margin: 10px 0;
    cursor: pointer;
}
#btn-outiline-secondary svg{
    margin-right: 10px;
}
#btn-outiline-secondary:hover{
    border-color: #005440;
    color: #005440;
}