.box-input-search-acs{
    display: flex;
    flex-direction: row;
    background-color: #f6f6f8;
    height: 42px;
    width: 100%;
    border-radius: 5px;
    align-items: center;
}
.box-input-search-acs .box-input{
    display: flex;
    flex: 21;
}
.box-input-search-acs .box-input input{
    width: 100%;
    border: none;
    outline: none;
    background-color: #f6f6f8;
    padding: 0 15px;
}

.box-input-search-acs .box-icon{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    border-left: solid 1px #DBDBDB;
    height: 30px;
}


@media (max-width: 1200px){
    .box-input-search-acs .box-input{
        flex: 15;
    }
}

@media (max-width: 800px){
    .box-input-search-acs .box-input{
        flex: 5;
    }
}

@media (max-width: 450px){
    .box-input-search-acs .box-input{
        flex: 3;
    }
}