.enviar{ background-color: #43B968; }
.enviado{ background-color: #ffee00; }
.fechado{ background-color: #f9805f; }
.arquivofechado{ background-color: #55d5f8; }
.arquivoperdido{ background-color: #00acf0; }
.cotar{ background-color: #ab06fd; }
.cotado{ background-color: #a39f9f; }
.encomendado{ background-color: #c2e143; }
.encomendar{ background-color: #f89500; }

.dotStatustb{
    border-radius: 15px;
    padding: 2px 16px;
    font-size: 12px;
}


.dotStatus{ 
    width: 15px;
    height: 15px;
    border-radius: 15px;
}

.box-actions-vendas{
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
}


/*PONTO DE VENDA*/
.container-pdv{
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 3;
    width: 100%;
    min-height: 100vh;
    background-color: #F8F8F8;
}
.header-pdv{
    display: flex;
    width: 100%;
    background-color: #003327;
    padding: 18px 0;
    border-top: solid 3px #02A680;
}

.header-pdv .container{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footer-pdv{
    display: flex;
    width: 100%;
    background-color: #005440;
    padding: 18px 0;
}

.footer-pdv .container{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

#corpo-pdv {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer-pdv .container .box-info{
    display: flex;
    align-items: center;
    border-left: solid 2px #fff;
    margin-left: 20px;
    padding-left: 20px;
}
.footer-pdv .container .box-info p{
    color: #fff;
    font-size: 13px;
}

.footer-pdv .container .box-subtotal{
    line-height: 0;
    color: #fff;
    margin-right: 25px;
    padding-right: 25px;
    border-right: solid 2px #fff;
} 
.footer-pdv .container .box-subtotal:nth-child(4){
    border: none;
    margin: 0;
    padding: 0;
}
.footer-pdv .container .box-subtotal p{
    font-size: 13px;
}



/*PONTO DE VENDA*/