.box-actions-fluxos{
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
}

.box-resumo-fluxo{
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px 10px;
    margin: 20px 0; 
    flex-wrap: wrap;
}

.box-resumo-saldo{
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 5px;
    padding: 3px 10px;
    margin: 20px 0; 
    flex-wrap: wrap;
}

/* Item resumo fluxo */
.item-resumo-fluxo{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    border-left: solid 1px #DFDFDF;
    flex-grow: 2; 
    padding: 10px;
}
.item-resumo-fluxo:nth-child(1){
    border: none;
}
.item-resumo-fluxo .circle-info{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70px;
    height: 70px;
    border-radius: 70px;
    background-color: #001A3E;
    padding: 3px;
}
.item-resumo-fluxo .circle-info p{
    color: #fff;
    font-size: 12px;
    line-height: 0;
}
.item-resumo-fluxo .info-valores{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.item-resumo-fluxo .info-valores h1{
    line-height: 0;
    font-size: 16px;
}
.item-resumo-fluxo .info-valores .info-variations{
    display: flex;
    flex-direction: column;
    border-top: solid 1px #DFDFDF;
    margin-top: 1px;
    padding-top: 10px;
}
.item-resumo-fluxo .info-valores .info-variations .variation{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -10px;
}

.item-resumo-fluxo .info-valores .info-variations .variation p{
    line-height: 2px;
    font-size: 14px;
    margin-left: 5px;
}
.display-data-fluxo-filtro{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 40px;
    margin-left: 5px;
    border: solid 1px #ebeaea;
    border-radius: 5px;
    cursor: pointer;
    padding: 0 10px;
}
.display-data-fluxo-filtro p{ font-size: 14px;}

