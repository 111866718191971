#button-squad-drop-down-green{
    display: flex;
    padding: 0;
    height: 50px;
    background-color: #18b306;
    border-radius: 5px;
    cursor: pointer;
}
#button-squad-drop-down-green svg{
    margin: 10px;
}
#button-squad-drop-down-green #btn-arrow-sdp{
    background-color: #249e16;
    height: 50px;
    min-width: 3px!important;
    padding: 0!important;
    margin: 0!important;
}
#button-squad-drop-down-green #btn-arrow-sdp svg{
    margin: 0;
}