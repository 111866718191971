.box-actions{
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
}
.box-steps{
    background-color: #fff;
    padding: 50px 10px;
}
.box-form-cadastro-pessoas{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;

    
}
.box-form-cadastro-pessoas form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
}
.box-form-cadastro-pessoas h2{
    color: #9C9C9C;
    font-weight: 400;
}
.box-buttons-steps{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.box-buttons-steps .box-tbn-steps{
    flex: 1;
}
.box-buttons-steps .box-tbn-steps:nth-child(2){
    margin-left: 10px;
}


.box-content{
    background-color: #fff;
    border-radius: 5px;
    padding: 25px;
    -webkit-box-shadow: 0px 9px 7px -6px rgba(0,0,0,0.04);
    -moz-box-shadow: 0px 9px 7px -6px rgba(0,0,0,0.04);
    box-shadow: 0px 9px 7px -6px rgba(0,0,0,0.04);
}
.box-content .title{ color: #4A4A4A; margin-top: 0px; font-size: 16px;}
.header-box-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.box-progress-foto-pessoa{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    background-color: #F3F5F8;
    border-radius: 5px;
}
.icon-upload-foto{
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #F3F5F8;
    cursor: pointer;
}
.box-foto-pessoa {
    height: 150px;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}



@media (max-width: 350px){
    .box-form-cadastro-pessoas form{
        width: 250px!important;
    }
}

@media (max-width: 600px){
    .box-form-cadastro-pessoas form{
        width: 300px;
    }
}

@media (max-width: 900px){
    .header-box-content{
        padding-bottom: 10px;
    }
}