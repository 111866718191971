

.botaoTipoPagamento{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.botaoTipoPagamento div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    border: solid 4px #02A680;
    opacity: 0.6;
}
.botaoTipoPagamento div p{
    font-weight: 700;
    color: #02A680;
    margin-top: 4px;
}
.botaoTipoPagamento div svg{
    margin-top: 7px;
}
.botaoTipoPagamento div:hover{
    opacity: 1;
}

.botaoTipoPagamento .active{
    opacity: 1;
    background-color: #02A680;
}
.botaoTipoPagamento .active p{
    color: #fff;
}
.botaoTipoPagamento svg g path{
    fill: #02A680;
}
.botaoTipoPagamento .active svg g path{
    fill: #fff;
}