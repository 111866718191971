.box-submenu{
    display: flex;
    flex-direction: row;
    background-color: #DFE2E8;
    padding: 3px 0;
}
.box-submenu .container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.box-submenu .item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 25px;
    flex-grow: 2;
}
.box-submenu .item a {
    display: flex;
    color: #4A4A4A;
}
.box-submenu .item:nth-child(1){
    margin-left: 0!important;
}
.box-submenu .item p{
    margin-left: 5px;
    font-size: 0.9em;
}
.box-submenu .item:hover{
    opacity: 0.5;
}
.box-submenu .active svg g path{
    fill: #02A680;
}
.box-submenu .active svg path{
    fill: #02A680;
}
.box-submenu .active p{
    color: #02A680;
} 

@media (max-width: 380px){
    .box-submenu .container .item{
        font-size: 0.9em;
    }
}
