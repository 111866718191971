.efetivada{ background-color: #43B968; }
.devolvida{ background-color: rgb(255, 142, 50); }
.cancelada{ background-color: #F44236; }

.dotStatus{ 
    width: 15px;
    height: 15px;
    border-radius: 15px;
}

.box-form-cadastro-compra{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;

    
}
.box-form-cadastro-compra form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.box-form-cadastro-compra h2{
    color: #9C9C9C;
    font-weight: 400;
}
.container-step-3-nova-compra{
    width: 900px;
}
.container-step-2-nova-compra{
    width: 500px;
}
.container-step-1-nova-compra{
    width: 450px;
}


.box-content-detalhes-estoque{
    background-color: #fff;
    border-radius: 5px;
    padding: 25px;
    -webkit-box-shadow: 0px 9px 7px -6px rgba(0,0,0,0.04);
    -moz-box-shadow: 0px 9px 7px -6px rgba(0,0,0,0.04);
    box-shadow: 0px 9px 7px -6px rgba(0,0,0,0.04);
}
.box-content-detalhes-estoque .title{ color: #4A4A4A; margin-top: 0px; font-size: 16px;}
.header-box-content-detalhes-estoque{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.box-footer-pagamentos-compra{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 18px;
}


/* Informações de pagamento */
.box-totais-pagamentos-compra{
    display: flex;
    flex-direction: row;
}
.box-totais-pagamentos-compra article{
    display: flex;
    align-items: center;
}
.box-totais-pagamentos-compra article:nth-child(2){
    margin: 0 23px;
    padding: 0 23px;
    border-left: solid 1px #E1E1E1;
    border-right: solid 1px #E1E1E1;
}
.box-totais-pagamentos-compra article aside{
    margin-left: 10px;
}
.box-totais-pagamentos-compra article aside p{
    font-size: 13px;
    line-height: 0;
}
.box-totais-pagamentos-compra article aside h1{
    font-size: 18px;
    line-height: 0;
}


.box-form-cadastro-compra{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;

    
}
.box-form-cadastro-compra form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.box-form-cadastro-compra h2{
    color: #9C9C9C;
    font-weight: 400;
}
.container-step-3-nova-compra{
    width: 900px;
}
.container-step-2-nova-compra{
    width: 500px;
}
.container-step-1-nova-compra{
    width: 450px;
}