.box-helpers-menu{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.box-helpers-menu div{
    list-style: none;
    margin: 0 25px;
}
.box-helpers-menu div a{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2B2B2B;
    font-size: 0.8em;
}
.box-helpers-menu div a:hover{
    opacity: 0.5!important;
}
.box-helpers-menu div a svg{ 
    margin-right: 5px;
    width: 20px;
    height: 20px;
}

.box-helpers-menu .light a{
    color: #fff!important;
}


@media (max-width: 600px){
    .box-helpers-menu{
        justify-content: space-between;
    }
    .box-helpers-menu div{
        list-style: none;
        margin: 0 10px;
        font-size: 11px;
        font-weight: bold;
    }
}

@media (max-width: 400px){
    .box-helpers-menu{
        justify-content: space-between;
        flex-direction: column;
    }
    .box-helpers-menu div{
        list-style: none;
        margin: 10px 10px;
        font-size: 13px;
        font-weight: bold;
    }
}