#button-squad-primary-for-input{
    height: 55px;
    min-width: 55px!important;
    background-color: #02A680;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5px;
  }
#button-squad-primary-for-input span{
  display: flex;
  padding: 0 10px;
}
#button-squad-primary-for-input p{
  color: #fff;
  margin-left: 10px;
}