#button-squad-drop-down-secondary{
    display: flex;
    padding: 0;
    height: 50px;
    background-color: #005440;
    border-radius: 5px;
    cursor: pointer;
}
#button-squad-drop-down-secondary svg{
    margin: 10px;
}
#button-squad-drop-down-secondary #btn-arrow-sdp{
    background-color: #014233;
    height: 50px;
    min-width: 3px!important;
    padding: 0!important;
    margin: 0!important;
}
#button-squad-drop-down-secondary #btn-arrow-sdp svg{
    margin: 0;
}