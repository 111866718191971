.dropdown-menu {
    display: flex;
    flex-direction: column;
    background: red;
    width: 200px;
    position: absolute;
    top: 70px;
    list-style: none;
    text-align: start;
  }
  
  .dropdown-menu li {
    margin-left: -40px;
    background: #ffffff;
    cursor: pointer;
    font-size: 0.9em;
  }
  
  .dropdown-menu li:hover {
    background: #02A680;
  }

  .dropdown-menu li a:hover {
    color: #fff;
  }
  
  .dropdown-menu.clicked {
    display: none;
  }
  
  .dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #4A4A4A;
    padding: 16px;
  }
  
  @media screen and (max-width: 960px) {
    .fa-caret-down {
      display: none;
    }
}
  