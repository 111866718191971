.footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #011b16;
    padding: 15px 0;
    margin-top: 50px;
}
.footer .container{
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footer .container .corp{
    display: flex;
    flex: 1;
}
.footer .container .box-helpers-menu{
    display: flex;
    flex: 1;
}
.footer .container .box-logo{
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

.footer .container .corp p{
    color: #fff;
    font-size: 0.7em;
}


@media (max-width: 750px){
    .footer .container{
        flex-direction: column;
    }
    .footer .container .corp{
        margin-bottom: 10px;
    }
    .footer .container .logo-link{
        margin-top: 25px;
    }
}