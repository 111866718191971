.navbar {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    height: 70px;
  }
  
  .nav-links {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    color: #4A4A4A;
    text-decoration: none;
    padding: 0 1rem;
    font-weight: 500;
    font-size: 0.9em;
  }
  .nav-links svg{
    margin-left: 6px;
    margin-top: 3px;
  }
  .nav-links:hover {
    background-color: #02A680;
    /* border-radius: 4px; */
    transition: all 0.2s ease-out;
  }
  
  .nav-item a:hover  {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      /* height: 100vh; */
      position: absolute;
      top: 70px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 1rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #02A680;
      border-radius: 0;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  

    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      background: #02A680;
      text-decoration: none;
      color: #fff;
      font-size: 1.5rem;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #02A680;
      transition: 250ms;
    }
  
  }
  