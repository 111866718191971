.header{
    background-color: #fff;
    border-top: solid 3px #02A680;
    -webkit-box-shadow: 0px 9px 8px -4px rgba(0,0,0,0.19);
    -moz-box-shadow: 0px 9px 8px -4px rgba(0,0,0,0.19);
    box-shadow: 0px 9px 8px -4px rgba(0,0,0,0.19);
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 70px;
    z-index: 2;
}
.header .container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.header .container .content-left{
    display: flex;
    flex-direction: row;
}
.header .container .box-logo{
    display: flex;
    align-items: center;
}
.header .container .menu{
    padding: 0;
    margin-left: 25px;
    border-left: solid 1px #DBDBDB;
}
.header .container .menu li{
    list-style: none;
    margin-left: 25px;
    float: left;
}
.header .container .menu li a{
    color: #4A4A4A;
    font-weight: 500;
    font-size: 1em;
}
.header .container .menu li a:hover{
    color: #000000; 
}
.header .container .menu .active a{
    color: #02A680
}

.header .container .box-user-info{
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.header .container .box-user-info .box-user{
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}

.header .container .box-user-info .user-info{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 10px;
}
.header .container .box-user-info .user-info h3{
    line-height: 0;
    color: #484848;
    font-size: 1em;
    margin-top: 0px;
}
.header .container .box-user-info .user-info p{
    line-height: 0;
    color: #9C9C9C;
    font-size: 0.8em;
    margin-top: 2px;
}
.header .container .box-user-info .user-info b{
    line-height: 0;
    color: #9C9C9C;
    font-size: 0.8em;
    margin-top: 2px;
}
.header .container .box-user-info .menu-mobile{
    display: none;
}
.header .container .box-user .box-avatar{
    border: solid 2px #2b2b2b;
    border-radius: 50%;
}

@media (max-width: 500px){
    .header .container .box-user-info .user-info{
        display: none;
    }
    .header .container .box-user-info .menu-mobile{
        margin-left: 10px!important;
    }
}

@media (max-width: 900px){
    .header .container .box-user-info .menu-mobile{
        display: initial;
        margin-left: 20px;
    }
    .header .container .menu{
        display: none;
    }
}